<template>
  <BaseReservation>
    <template v-slot:default>
      <v-card class="pa-3" elevation="0">
        <v-row class="flex-header pa-1 d-flex">
          <div class="d-flex align-center mr-5">
            <span class="custom-filed">{{ $t('common.facility') }}</span>
             <v-select
                v-model="facilitySelected"
                :items="facilityList"
                item-text="name"
                item-value="id"
                dense
                small
                color="var(--text__gray)"
                outlined
                hide-details
                class="v-select-custom ml-1 facility-list"
              ></v-select>
          </div>
          <div class="d-flex align-center mr-5">
            <span class="custom-filed">{{ $t('common.contract') }}</span>
            <v-select
              v-model="codeBookingType"
              :items="bookingTypeList"
              item-text="code"
              item-value="id"
              style="min-width: 150px"
              dense
              small
              color="var(--text__gray)"
              outlined
              hide-details
              class="v-select-custom ml-1"
            ></v-select>
          </div>
          <div class="d-flex align-center mr-5">
            <span class="custom-filed">{{ $t('reservation.reservationSource') }}</span>
            <v-select
              v-model="salesChannel"
              :items="listSalesChannel"
              item-text="value"
              item-value="key"
              style="min-width: 150px"
              dense
              small
              color="var(--text__gray)"
              outlined
              hide-details
              class="v-select-custom ml-1"
            ></v-select>
          </div>
          <div class="d-flex align-center mr-5">
            <v-checkbox
              v-model="includeInvoiced"
              label="請求明細領収書発行登録済みも表示"
              class="text-size-normal custom-checkbox"
            >
            </v-checkbox>
          </div>
        </v-row>
        <v-row class="pr-0 mr-0">
          <v-col :cols="12" class="pa-0 mx-0">
            <Table
              :attr="{
                'item-key': 'id',
              }"
              ref="table"
              :items="bookingList"
              :headers="header"
              :itemsPerPage="10"
              :total="totalCount"
              :itemsPerPageOptions="[10, 30, 50, 100, 200]"
              :funReset="getBookingList"
              :filter="filter"
              :multi-sort="true"
              :sortField="[]"
            >
              <template v-slot:[`item.edit`]="{item}">
                <v-btn
                  :to="
                    '/reservation/contract-detail?id=' + item.id + '&tab=basic-information'"
                  target="_blank"
                  :disabled="!checkUser"
                  color="var(--bt__sea-blue)"
                  outlined
                  class="text-size-normal"
                >{{ $t('buttons.edit') }}</v-btn>
              </template>

              <template v-slot:[`item.contract`]="{item}">
                <span v-if="item.bookingType.code">{{ item.bookingType.code }}</span>
              </template>

              <template v-slot:[`item.cancelledAt`]="{item}">
                <span v-if="item.cancelledAt">{{ item.cancelledAt | dateTimeSting }}</span>
              </template>

              <template v-slot:[`item.totalCancelAmount`]="{item}">
                <span >{{ item.computedForCancelList.cancelJpy.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' }) }}</span>
              </template>

              <template v-slot:[`item.totalCancelPoint`]="{item}">
                <span v-if="item.bookingType.code === 'SP'">{{ item.computedForCancelList.cancelSp }}pt</span>
                <span v-else>{{ item.computedForCancelList.cancelFp }}pt</span>
              </template>

              <template v-slot:[`item.facility`]="{item}">
                <span v-if="item.room.roomType.facility.name">{{ item.room.roomType.facility.name }}</span>
              </template>

              <template v-slot:[`item.roomType`]="{item}">
                <span v-if="item.room.roomType.name">{{ item.room.roomType.name }}</span>
              </template>

              <template v-slot:[`item.checkInDate`]="{item}">
                <span v-if="item.checkInDate">{{ item.checkInDate.replace(/-/g, '/') }}</span>
              </template>

              <template v-slot:[`item.checkOutDate`]="{item}">
                <span v-if="item.checkOutDate">{{ item.checkOutDate.replace(/-/g, '/') }}</span>
              </template>

              <template v-slot:[`item.numOfDays`]="{item}">
                <span>{{ getNumOfDay(item) }}</span>
              </template>

              <template v-slot:[`item.reservationSource`]="{item}">
                <span v-if="item.salesChannel">{{ item.salesChannel | salesChannelName }}</span>
              </template>

              <template v-slot:[`item.createdAt`]="{item}">
                  <span v-if="item.createdAt">{{ item.createdAt | dateTimeSting }}</span>
              </template>

            </Table>
          </v-col>
        </v-row>
      </v-card>
    </template>
  </BaseReservation>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions'
import BaseReservation from '@/components/Reservation/BaseReservation'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import { BOOKING_TYPES_LIST } from '@/api/graphql/bookingDetail/booking-detail-basic'
import { BOOKING_LIST } from '@/api/graphql/guestRoomDailyReport/cancellation-applied'
import {
  SALES_CHANEL_ENUM_BOOKING_LIST
} from '@/constants/enum'
import gql from 'graphql-tag'
import Table from '@/components/Table'

export default {
  name: 'ReservationCancellationApplied',
  data () {
    return {
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      includeInvoiced: false,
      salesChannel: null,
      listSalesChannel: SALES_CHANEL_ENUM_BOOKING_LIST,
      codeBookingType: null,
      bookingTypeList: [],
      facilitySelected: null,
      facilityList: [],
      bookingList: [],
      totalCount: 0,
      header: [
        { text: '', value: 'edit', sortable: false },
        { text: this.$t('reservation.reservationID'), value: 'id', sortable: true, fieldName: 'booking.id' },
        { text: this.$t('common.contract'), value: 'contract', sortable: false },
        { text: this.$t('booking.cancelDate'), value: 'cancelledAt', sortable: true, fieldName: 'booking.cancelledAt' },
        { text: this.$t('booking.totalCancelAmount'), value: 'totalCancelAmount', sortable: true, fieldName: 'computedForCancelList.cancelJpy' },
        { text: this.$t('booking.totalCancelPoint'), value: 'totalCancelPoint', sortable: false },
        { text: this.$t('common.facility'), value: 'facility', sortable: false },
        { text: this.$t('common.roomType'), value: 'roomType', sortable: false },
        { text: this.$t('common.checkIn'), value: 'checkInDate', sortable: true, fieldName: 'booking.checkInDate' },
        { text: this.$t('common.checkOut'), value: 'checkOutDate', sortable: true, fieldName: 'booking.checkOutDate' },
        { text: this.$t('common.numOfDays'), value: 'numOfDays', sortable: false },
        { text: this.$t('reservation.reservationSource'), value: 'reservationSource', sortable: true, fieldName: 'booking.salesChannel' },
        { text: this.$t('reservation.reservationDate'), value: 'createdAt', sortable: true, fieldName: 'booking.createdAt' }
      ]
    }
  },
  computed: {
    filter () {
      return {
        hasPendingCancelFee: true,
        includeInvoiced: this.includeInvoiced,
        facilityId: this.facilitySelected,
        bookingTypeId: this.codeBookingType,
        salesChannel: this.salesChannel
      }
    }
  },
  watch: {
    filter: {
      deep: true,
      handler () {
        this.$nextTick(() => this.reload())
      }
    }
  },
  mounted () {
    this.getFacility()
    this.getBookingTypeList()
    this.reload()
  },
  methods: {
    getNumOfDay (item) {
      const checkout = new Date(item.checkOutDate)
      const checkin = new Date(item.checkInDate)
      var t2 = checkout.getTime()
      var t1 = checkin.getTime()

      return parseInt((t2 - t1) / (24 * 3600 * 1000))
    },

    async getFacility () {
      await this.$apollo.query({
        query: gql`${FACILITY_LIST}`
      }).then((data) => {
        this.facilityList = data.data.facilityList.slice()
        const item = {
          id: null,
          name: '全て'
        }
        this.facilityList.unshift(item)
      }).catch((error) => {
        console.error(error)
      })
    },

    async getBookingTypeList () {
      await this.$apollo.query({
        query: gql`${BOOKING_TYPES_LIST}`
      }).then((data) => {
        this.bookingTypeList = data.data.bookingTypesList.slice()
        const item = {
          id: null,
          code: '全て',
          name: '全て'
        }
        this.bookingTypeList.unshift(item)
      }).catch((error) => {
        console.error(error)
      })
    },
    reload () {
      this.$refs.table.reset()
    },
    async getBookingList (variables) {
      await this.$loading(async () => {
        const result = await this.$apollo.query({
          query: gql`${BOOKING_LIST}`,
          variables: {
            filter: variables.filter,
            pagination: variables.pagination,
            orderBy: variables.orderBy
          },
          fetchPolicy: 'no-cache'
        })
        this.bookingList = result.data.bookingList.items
        this.totalCount = result.data.bookingList.total
      })
    }
  },
  components: {
    BaseReservation,
    Table
  }
}
</script>

<style scoped lang="scss">
  ::v-deep {
    .table-custom.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      color: #757575 !important;
      font-size: 14px !important;
    }
    .v-data-table {
      tbody tr {
        background-color: #E0E0E0 !important
      }
      tbody tr td{
        color: #424242 !important;
        font-size: 16px !important;
      }
       .theme--light {
          background-color: #ffffff !important;
        }
    }
    .custom-checkbox .v-label {
       color: #000000 !important;
    font-size: 12px !important;
    }
    .custom-filed {
    color: #000000 !important;
    font-size: 12px !important;
  }
  }
</style>
