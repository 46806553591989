import gql from 'graphql-tag'

export const BOOKING_LIST = gql`
query (
  $filter: BookingListFilterInput
  $pagination: PaginationInput
  $orderBy: [OrderByInput!]
) {
    bookingList(
      filter: $filter
      loadCancelListComputed: true
      pagination: $pagination
      orderBy: $orderBy
    ) {
      items {
        id
        bookingType {name code id}
        bookingGroupId
        computedForCancelList {
          cancelJpy
          cancelSp
          cancelFp
        }
        room {
          name 
          roomType {
            name 
            facility{
              name 
            }
          }
        }
        cancelledAt
        checkInDate 
        checkOutDate  
        salesChannel
        createdAt 
      }
      total
    }
  }
`
